<!--页面代码从这里开始拷贝-->
<template>
  <div>
    <table-compnent :records="tableData" :showSelection="false" @resetHandler="reset" @searchHandler="doSearch"
      @batchDeleteHandler="batchDelete" @addHandler="saveTripMotorCade" :loading="loading" :showDelete="false"
      :showAdd="existsCompanyNo">
      <template v-slot:search>
        <el-form-item label="车队名称:">
          <el-input v-model="search.name" placeholder="请输入车队名称"></el-input>
        </el-form-item>
      </template>
      <template v-slot:content>
        <el-table-column prop="name" label="车队名称"></el-table-column>
        <el-table-column prop="carNum" label="车辆数"></el-table-column>
        <el-table-column prop="leaderName" label="车队负责人"></el-table-column>
        <el-table-column prop="mobile" label="联系电话"></el-table-column>
        <el-table-column prop="companyName" label="所属公司"></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="queryById(scope.row.id)" v-if="existsCompanyNo">编辑</el-button>
            <el-button type="text" size="small" v-if="isCcustomer"
              @click="deleteById(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </template>
    </table-compnent>
  </div>
</template>
  
  <script>
import TripMotorCadeApi from "@/api/TripMotorCadeApi";
import listPage from "../../base/listPage.vue";
export default {
  name: "TripMotorCadeList",
  extends:listPage,
  data() {
    return {
      dialogFormVisible: false,
      currentTripMotorCade: null,
      tableData: {},
      search: {
        name: "",
      },
      page: {},
    };
  },
  methods: {
    reset() {
      this.search = this.$options.data().search;
      this.doSearch({ pageSize: 20, pageNum: 1 });
    },
    doSearch(params) {
      this.loading = true;
      this.page = params;
      this.search.pageSize = this.page.pageSize;
      this.search.pageNum = this.page.pageNum;
      TripMotorCadeApi.search(this.search).then((resp) => {
        if (resp.code === '200') {
          this.tableData = resp.data;
          this.loading = false;
        }
      });
    },
    saveTripMotorCade() {
      this.navTo("/saveTripMotorCade");
    },
    queryById(id) {
      TripMotorCadeApi.queryTripMotorCadeById({ id: id }).then((resp) => {
        if (resp.code === '200') {
          this.queryParam("saveTripMotorCade", resp.data);
        }
      });
    },
    deleteById(id) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          TripMotorCadeApi.deleteTripMotorCade({ id: id }).then((resp) => {
            if (resp.code === '200') {
              this.$successMsg(resp.msg);
              this.doSearch(this.page);
            } else {
              this.$errorMsg(resp.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    batchDelete(params) {
      TripMotorCadeApi.batchDeleteTripMotorCade(params).then((resp) => {
        if (resp.code === '200') {
          this.$successMsg(resp.msg);
          this.doSearch(this.page);
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .fleetDialog {
  width: 500px;

  .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
  }

  .el-dialog__footer {
    border-top: 1px solid #ebebeb;
  }

  .el-form-item__label {
    width: 120px;
  }

  .el-form-item__label::after {
    content: ":";
  }

  .el-input {
    width: 300px;
  }
}
</style>